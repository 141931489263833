import axios from 'axios'
import appConfig from 'configs/app.config'
import {
    TOKEN_TYPE,
    REQUEST_HEADER_AUTH_KEY,
    REFRESH_TOKEN,
    UNAUTORIZED_CODE,
} from 'constants/api.constant'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import { apiRefreshToken } from './AuthService'
import { setUser } from 'store/auth/userSlice'

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiBaseUrl,
    withCredentials: true,
})

BaseService.interceptors.request.use(
    (config) => {
        const token = persistAccessToken()
        if (token && config.url.indexOf(REFRESH_TOKEN) === -1) {
            config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${token}`
        }
        // Inject `X-Partner-Id` if `repClient` exists in localStorage and is truthy
        const repClient = localStorage.getItem('repClient')
        if (repClient) {
            config.headers['X-Partner-Id'] = repClient
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response } = error

        const originalRequest = error.config
        if (response && UNAUTORIZED_CODE.includes(response?.status)) {
            const access_token = await refreshAccessToken()
            axios.defaults.headers.common[REQUEST_HEADER_AUTH_KEY] =
                TOKEN_TYPE + access_token
            return BaseService(originalRequest)
        }
        if (response?.status !== 200) {
            // store.dispatch(onSignOutSuccess());
        }
        return Promise.reject(error)
    }
)

const persistAccessToken = () => {
    const token = localStorage.getItem('token') || ''
    if (token) return token
    const { auth } = store.getState()
    const tokenFromStore = auth?.session?.token
    if (tokenFromStore) return tokenFromStore
    return ''
}

const setNewAccessTokenValue = (newToken) => {
    if (!newToken) return
    localStorage.setItem('token', newToken)
    const { auth } = store.getState()
    store.dispatch(
        setUser({
            ...auth,
            session: { ...auth.session, token: newToken },
            user: { ...auth.user, token: newToken },
        })
    )
}

const refreshAccessToken = async () => {
    try {
        const response = await apiRefreshToken()
        const { token } = response?.data
        setNewAccessTokenValue(token)
        // Retry the original request with the new access token
        return token
    } catch (error) {
        // Handle error while refreshing the token => redirect to login
        localStorage.clear()
        store.dispatch(onSignOutSuccess())
        throw error
    }
}

export default BaseService
