import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        signedIn: false,
        isRep: false,
        selectedRepClient: '',
        partnersList: null,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.isRep = false
            state.selectedRepClient = ''
            state.partnersList = []
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setIsRep: (state, action) => {
            state.isRep = action.payload
        },
        setSelectedRepClient: (state, action) => {
            state.selectedRepClient = action.payload
        },
        setPartnersList: (state, action) => {
            state.partnersList = action.payload
        },
    },
})

export const {
    onSignInSuccess,
    onSignOutSuccess,
    setToken,
    setIsRep,
    setSelectedRepClient,
    setPartnersList,
} = sessionSlice.actions

export default sessionSlice.reducer
