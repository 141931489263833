import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import selectedAction from './selectedAction/selectedActionSlice'
import uploadHelper from './uploadHelper/uploadHelperSlice'
import catalogues from './catlogues/cataloguesSlice'
import catalogueDetails from './catlogues/catalogueDetailsSlice'
import facets from './catlogues/facetsSlice'
import currentOrders from './orders/currentOrdersSlice'
import addresses from './orders/addressesSlice'
import createdOrders from './createdOrders/createdOrdersSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        selectedAction,
        uploadHelper,
        catalogues,
        catalogueDetails,
        facets,
        currentOrders,
        addresses,
        createdOrders,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
