import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import {
    onSignInSuccess,
    onSignOutSuccess,
    setSelectedRepClient,
    setIsRep,
    setPartnersList,
} from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { resetOrdersData } from 'views/orders/constants'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { signedIn, isRep, selectedRepClient } = useSelector(
        (state) => state.auth.session
    )

    const token = localStorage.getItem('token')

    const signIn = async (values, callApi = true) => {
        try {
            if (callApi) {
                const resp = await apiSignIn(values)
                if (resp.data) {
                    const {
                        token,
                        data: { isRep, partnersList, grossPriceMode },
                    } = resp.data
                    dispatch(setIsRep(isRep))
                    dispatch(setPartnersList(partnersList))
                    dispatch(
                        setUser(
                            {
                                ...resp.data?.data,
                                email: values.login,
                                authority: ['ADMIN'],
                                avatar: '',
                                isRep,
                            } || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                    dispatch(onSignInSuccess(token))
                    localStorage.setItem('token', token)
                    localStorage.setItem('grossPriceMode', grossPriceMode)
                    localStorage.setItem('userName', resp?.data?.data?.userName)
                    localStorage.setItem('email', values.login)
                    if (isRep && !selectedRepClient) {
                        return
                    }
                }
            }

            const redirectUrl = query.get(REDIRECT_URL_KEY)
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            )
            return {
                status: 'success',
                message: '',
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        localStorage.clear()
        resetOrdersData(dispatch)
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: !isRep
            ? token && signedIn
            : token && signedIn && selectedRepClient,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
