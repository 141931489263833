import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PAGE_LIMIT } from 'constants/app.constant'
import BaseService from 'services/BaseService'

export const fetchCreatedOrders = createAsyncThunk(
    'createdOrder/fetch',
    async (query) => {
        const page = query?.page || 1
        const limit = query?.limit || PAGE_LIMIT
        try {
            const response = await BaseService.get(
                `/order/?page=${page}&limit=${limit}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch orders')
        }
    }
)

// Redux Toolkit slice
const createdOrdersSlice = createSlice({
    name: 'createdOrders',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
    },
    reducers: {
        resetData: (state) => {
            state.data = []
            state.isLoading = false
            state.error = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreatedOrders.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCreatedOrders.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchCreatedOrders.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
    },
})
export const { resetData } = createdOrdersSlice.actions

export const selectData = (state) => state.createdOrders.data
export const selectIsLoading = (state) => state.createdOrders.isLoading
export const selectError = (state) => state.createdOrders.error

export default createdOrdersSlice.reducer
