import {
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    TYPES,
} from 'constants/notifications.constant'
import { resetAddresses } from 'store/orders/addressesSlice'
import { clearOrders } from 'store/orders/currentOrdersSlice'
import { deleteFromLocalStorage } from 'utils/deleteFromLocalStorage'
import { feedbackTitleHelper } from 'utils/feedbackTitleHelper'

const { addTitle, editTitle, deleteTitle } =
    feedbackTitleHelper('de la commande')
const { SUCCESS, ERROR } = TYPES

export const notificationConfig = {
    creation: {
        success: {
            type: SUCCESS,
            title: addTitle,
            duration: 3000,
            message: SUCCESS_MESSAGE,
        },
        error: {
            type: ERROR,
            title: addTitle,
            duration: 3000,
            message: ERROR_MESSAGE,
        },
    },
    edit: {
        success: {
            type: SUCCESS,
            title: editTitle,
            duration: 3000,
            message: SUCCESS_MESSAGE,
        },
        error: {
            type: ERROR,
            title: editTitle,
            duration: 3000,
            message: ERROR_MESSAGE,
        },
    },
    deletion: {
        success: {
            type: SUCCESS,
            title: deleteTitle,
            duration: 3000,
            message: SUCCESS_MESSAGE,
        },
        error: {
            type: ERROR,
            title: deleteTitle,
            duration: 3000,
            message: ERROR_MESSAGE,
        },
    },
}

export const removeOrderDataFromLocalStorage = () => {
    deleteFromLocalStorage('isOrderExclusive')
    deleteFromLocalStorage('orderCampaignId')
    deleteFromLocalStorage('orderId')
    deleteFromLocalStorage('currency')
}

export function formatOrderData(orderData) {
    return {
        orderCampaignId: orderData?.campaignId,
        subTotal: orderData?.subTotal,
        totalDiscountValue: orderData?.totalDiscountValue,
        totalDiscounts:
            orderData?.totalDiscounts?.map((discount) => ({
                salesPromotionId: discount?.salesPromotionId,
                promotionLineName: discount?.promotionLineName,
                applyTo: discount?.applyTo,
                promotionType: discount?.promotionType,
                discountType: discount?.discountType,
                discount: discount?.discount,
            })) || [],
        totalExclTax: orderData?.totalExclTax,
        taxes:
            orderData?.taxes?.map((tax) => ({
                _id: tax?._id,
                name: tax?.name,
                rate: tax?.rate,
                value: tax?.value,
            })) || [],
        totalTax: orderData?.totalTax,
        totalInclTax: orderData?.totalInclTax,
        currency: {
            _id: orderData?.currency?._id,
            code: orderData?.currency?.code,
            name: orderData?.currency?.name,
        },
        orderId: orderData?._id,
        updatedLines:
            orderData?.lines?.map((line) => ({
                _id: line?._id,
                campaignId: line?.campaignId,
                item: {
                    baseUnit: {
                        _id: line?.item?.baseUnit?._id,
                        name: line?.item?.baseUnit?.name,
                    },
                    _id: line?.item?._id,
                    sku: line?.item?.sku,
                    title: line?.item?.title,
                    pictures:
                        line?.item?.pictures?.map((picture) => ({
                            pictureUrl: picture?.pictureUrl,
                        })) || [],
                },
                quantity: line?.quantity,
                salesUnit: {
                    _id: line?.salesUnit?._id,
                    name: line?.salesUnit?.name,
                    quantityInBaseUnit: line?.salesUnit?.quantityInBaseUnit,
                },
                grossPrice: line?.grossPrice,
                netPrice: line?.netPrice,
                discounts:
                    line?.discounts?.map((discount) => ({
                        promotionType: discount?.promotionType,
                        discountType: discount?.discountType,
                        discount: discount?.discount,
                        ...(discount?.salesPromotionId && {
                            salesPromotionId: discount?.salesPromotionId,
                        }),
                        ...(discount?.promotionLineName && {
                            promotionLineName: discount?.promotionLineName,
                        }),
                        ...(discount?.applyTo && {
                            applyTo: discount?.applyTo,
                        }),
                    })) || [],
                lineTotalExclTax: line?.lineTotalExclTax,
                lineTotalInclTax: line?.lineTotalInclTax,
                tax: {
                    _id: line?.tax?._id,
                    name: line?.tax?.name,
                    rate: line?.tax?.rate,
                    value: line?.tax?.value,
                },
            })) || [],
    }
}

export function resetOrdersData(dispatch) {
    dispatch(clearOrders())
    dispatch(resetAddresses())
    removeOrderDataFromLocalStorage()
}

export const getCurrency = (currency) => {
    return currency?.code || currency?.name
}
